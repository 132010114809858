@import "./pages/style.scss";
@import "./components/style.scss";

.App {
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    background: center/100% 100% no-repeat
            linear-gradient(
                180deg,
                rgba(65, 73, 158, 0.9) -105.42%,
                rgba(21, 22, 58, 0.9) 69.76%
            ),
        center/100% 100% no-repeat url("../public/assets/app-bg.gif");
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

* {
    ::-webkit-scrollbar {
        width: 2px;
    }
}
