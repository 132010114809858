.app-content {
  width: 100%;
  padding: 20px 15%;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  .main-contents-container {
    .ant-space-item {
      display: flex;
      justify-content: center;
    }
  }
  .casino-container {
    border-radius: 20px;
    padding: 10px 0;
  }
}
