.app-header {
  width: 100vw;
  min-height: 135px;
  padding: 20px 10%;
  justify-content: space-between;
  box-sizing: border-box;
  .social-icon {
    cursor: pointer;
  }
}
